import TableForm from "../../components/TableForm";

export default function CreateTable() {
  return (
    <>
      <h1 className="title">Create Table</h1>
      <TableForm />
    </>
  );
}
